import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Alert, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input, FormGroup, FormFeedback } from "reactstrap"
import NavBar from './Navbar'
import Section from "./Section"
import Footer from "../../A_LilTriangle/Footer"
import BackToTop from '../../../components/Layout/backToTop'
import Parse from 'parse'
import FeatherIcon from "feather-icons-react"
import * as Yup from "yup"
import { useFormik } from "formik"

const JobListTwo = () => {

    const [allJobList, setAllJobList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [jobType, setJobType] = useState()
    const [location, setLocation] = useState()
    const [openPopup, setOpenPopup] = useState(false)
    const [succeMsg, setsucceMsg] = useState(false)


    const locations = [
        { value: "All", label: "All" },
        { value: "Bengaluru", label: "Bengaluru" },
        { value: "Hyderabad", label: "Hyderabad" },
        { value: "Chennai", label: "Chennai" },
        { value: "Mumbai", label: "Mumbai" },
        { value: "Navi Mumbai", label: "Navi Mumbai" },
        { value: "Pune", label: "Pune" },
        { value: "Dehli", label: "Dehli" },
        { value: "Noida", label: "Noida" },
        { value: "Gurugram", label: "Gurugram" },
        { value: "Ahmedabad", label: "Ahmedabad" },
        { value: "Vadodara", label: "Vadodara" },
        { value: "Others", label: "Others" }
    ]

    useEffect(() => {
        setIsLoading(true)
        getjobs(0)
    }, [])

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: "",
            city: "",
            phone: "",
            email: "",
            schoolDetails: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            city: Yup.string().required("Please Enter City/Area"),
            email: Yup.string().required("Email is required"),
            phone: Yup.string().required("Phone is required"),
        }),

        onSubmit: (values) => {
            setsucceMsg(true)
            console.log(values)

            if (values.name !== "" || values.city !== "" || values.phone !== "" || values.email !== "") {
                var name = values.name
                var city = values.city
                var phone = values.phone
                var email = values.email
                var school = values.schoolDetails
                console.log(1)
                fetch(
                    // "https://development.back4app.io/addWebsiteQuery", //development
                    "https://liltriangle.b4a.app/addWebsiteQuery", //production
                    {
                        method: "POST",
                        body: JSON.stringify({
                            name: name,
                            city: city,
                            email: email,
                            phone: phone,
                            schoolDetails:  school,
                            clientType: "Job Post Request"

                        }),
                        headers: {
                            "Content-type": "application/json charset=UTF-8",
                        },
                    }
                ).then((response) => {
                    console.log('response', response)
                    // setOnSave(false)
                    if (response.status === 200) {
                        // setResponce("SUCCESS")
                        // setError(undefined)
                        setTimeout(() => {
                            // setResponce(undefined)
                        }, 2000)
                        clearValues()
                    } else {

                        response.json().then((data) => {
                            console.log(data)
                            // setError(data.error)
                            // setResponce(undefined)
                        })
                    }


                }, (error) => {
                    console.log("error" + error.message)
                })
            }

        }
    },
    )

    const clearValues = () => {
        validation.resetForm()
    }

    const getjobs = (skip, type, city) => {
        Parse.Cloud.run("function_getAllJobNotifications", {
            skip: skip,
            designation: type,
            location: city,
            allCities: ["Dehli", "Bengaluru", "Noida", "Chennai", "Hyderabad", "Mumbai", "Pune", "Gurugram", "Ahmedabad", "Vadodara"]
        }).then((result) => {
            console.log(result)
            setIsLoading(false)
            setLoadMore(false)
            let newList = [...allJobList, ...result]
            setAllJobList(skip == 0 ? result : newList)
        },
            (error) => {
                console.log(error)
            }
        )
    }

    return (
        <React.Fragment>
            <Modal isOpen={openPopup}>
                <ModalHeader toggle={() => {
                    setOpenPopup(false)
                }}>
                    Job Post
                </ModalHeader>
                <ModalBody>
                    <p className='text-center'>If you're already a lilTriangle user, simply log in to your school dashboard and create a job posting directly on the lilTriangle platform.</p>
                    <p className='text-center'>OR</p>
                    <Row>
                        <Col
                            lg="12"
                            md={{ size: 6, order: 1 }}
                            xs={{ order: 2 }}
                            className="mt-4 pt-2"
                        >
                            <Card
                                className="rounded shadow border-0"
                            >
                                <CardBody>
                                    <div className="custom-form bg-white">
                                        <div id="message"></div>
                                        <Alert
                                            color="info"
                                            isOpen={succeMsg}
                                            toggle={() => {
                                                setsucceMsg(false)
                                            }}
                                        >
                                            Thanks for enquiring at lil Triangle, one of our executives will get in touch with you shortly.                  </Alert>
                                        <Form
                                            method="post"
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                            name="contact-form"
                                            id="contact-form"
                                        >
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label className="form-label">
                                                            Name <span className="text-danger">*</span>
                                                        </Label>
                                                        <div className="form-icon position-relative">
                                                            <i>
                                                                <FeatherIcon
                                                                    icon="user"
                                                                    className="fea icon-sm icons"
                                                                />
                                                            </i>
                                                        </div>
                                                        <Input
                                                            name="name"
                                                            id="textName"
                                                            type="text"
                                                            className="form-control ps-5"
                                                            placeholder="First Name :"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.name || ""}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.name}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label className="form-label">
                                                            City/Area <span className="text-danger">*</span>
                                                        </Label>
                                                        <div className="form-icon position-relative">
                                                            <i>
                                                                <FeatherIcon
                                                                    icon="user"
                                                                    className="fea icon-sm icons"
                                                                />
                                                            </i>
                                                        </div>
                                                        <Input
                                                            name="city"
                                                            id="textCity"
                                                            type="text"
                                                            className="form-control ps-5"
                                                            placeholder="City/Area:"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.city || ""}
                                                            invalid={
                                                                validation.touched.city && validation.errors.city
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.city && validation.errors.city ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.city}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label className="form-label">
                                                            Phone Number{" "}
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <div className="form-icon position-relative">
                                                            <i>
                                                                <FeatherIcon
                                                                    icon="phone"
                                                                    className="fea icon-sm icons"
                                                                />
                                                            </i>
                                                        </div>
                                                        <Input
                                                            name="phone"
                                                            id="textPhone"
                                                            type="phone"
                                                            className="form-control ps-5"
                                                            placeholder="Your Phone Number :"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.phone || ""}
                                                            invalid={
                                                                validation.touched.phone &&
                                                                    validation.errors.phone
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.phone &&
                                                            validation.errors.phone ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.phone}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label className="form-label">
                                                            Email <span className="text-danger">*</span>
                                                        </Label>
                                                        <div className="form-icon position-relative">
                                                            <i>
                                                                <FeatherIcon
                                                                    icon="mail"
                                                                    className="fea icon-sm icons"
                                                                />
                                                            </i>
                                                        </div>
                                                        <Input
                                                            name="email"
                                                            id="textEmail"
                                                            type="email"
                                                            className="form-control ps-5"
                                                            placeholder="Your email :"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                validation.touched.email &&
                                                                    validation.errors.email
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.email &&
                                                            validation.errors.email ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.email}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-3">
                                                        <Label className="form-label">
                                                            Preschool Details and Query
                                                        </Label>
                                                        <textarea
                                                            name="schoolDetails"
                                                            id="textSchoolDetails"
                                                            rows="3"
                                                            className="form-control"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.schoolDetails || ""}
                                                        ></textarea>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" className="text-center">
                                                    <div className="d-grid">
                                                        <input
                                                            type="submit"
                                                            id="submit"
                                                            name="send"
                                                            className="submitBnt btn btn-primary"
                                                            value="Submit"
                                                        />
                                                        <div id="simple-msg"></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='secondary'
                        className='btn '
                        onClick={() => {
                            setOpenPopup(false)
                        }}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <NavBar />
            <Section title={"Jobs"} />

            <section className="section">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="features-absolute">
                                <form className="rounded p-4 mt-4 bg-white shadow">
                                    <Row className="text-start">
                                        <Col lg={6} md={6}>
                                            <div className="mb-0">
                                                <select
                                                    className="form-control form-select"
                                                    value={jobType}
                                                    onChange={(e) => setJobType(e.target.value)}>
                                                    <option disabled>Job Types</option>
                                                    <option value="All">All</option>
                                                    <option value="Center Head">Center Head</option>
                                                    <option value="Teacher">Teacher</option>
                                                </select>
                                            </div>
                                        </Col>

                                        <Col lg={4} md={4}>
                                            <div className="mb-0">
                                                <select
                                                    className="form-control form-select"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}>
                                                    <option disabled>Location</option>
                                                    {locations.map((loc, index) => (
                                                        <option key={index} value={loc.value}>{loc.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={2} md={2}>
                                            <div className="col-md-4 mt-4 mt-sm-0">
                                                <div className="d-grid">
                                                    <Button color='primary' onClick={() => {
                                                        setAllJobList([])
                                                        setIsLoading(true)
                                                        getjobs(0, jobType == "All" ? undefined : jobType, location == "All" ? undefined : location)
                                                    }}  >Search</Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className=" mt-sm-0">
                    <Row>
                        <Col xs={12}>
                            <Alert style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)', borderColor: 'rgba(0, 123, 255, 0.1)', color: '#007bff', }}>
                                <Row>
                                    <Col xs={11}>
                                        <p className='m-1'>If you're a school in search of new hires and wish to post your requirements here, please reach out to lilTriangle by clicking on the "Contact" button.</p>
                                    </Col>
                                    <Col xs={1}>
                                        <div className="float-end">
                                            <Link to='#'
                                                className="btn btn-primary btn-sm"
                                                onClick={() => {
                                                    setOpenPopup(true)
                                                }}
                                            >
                                                Contact
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Alert>
                        </Col>
                    </Row>
                    {isLoading && (
                        <Row>
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                        Loading....{" "}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {allJobList.length == 0 && !isLoading && <Row>
                        <h4 className="text-info text-center">No Jobs Found for this Filter!!</h4>
                    </Row>}

                    <Row>
                        {allJobList.map((item, key) => (
                            <Col md={6} key={item.id} className="mt-0">
                                <Link to={`/liltriangle-job-detail/${item.id}`} className="">
                                    <div className="job-box d-md-flex align-items-center border-0 shadow rounded p-2 position-relative">
                                        <div className="ms-md-4 mt-4 mt-sm-0 p-4">
                                            <Link to={`/liltriangle-job-detail/${item.id}`} className="text-dark h5">{item.attributes.designation}</Link>
                                            <ul className="list-unstyled mb-0 mt-1">
                                                <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">School: <Link to="#" className="text-dark">{item.attributes.schoolName}</Link></li>{" "}
                                            </ul>
                                            <ul className="list-unstyled mb-0 mt-0">
                                                <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">Location: <Link to="#" className="text-dark">{item.attributes.area}, {item.attributes.location}</Link></li>{" "}
                                            </ul>
                                            <ul className="list-unstyled mb-0 mt-0">
                                                <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">Experience: <Link to="#" className="text-dark">{item.attributes.experience}</Link></li>{" "}
                                            </ul>
                                            <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                                                Qualification:{" "}
                                                {item.attributes.qualifications && item.attributes.qualifications.length > 0 && (
                                                    <Link to="#" className="text-dark">{item.attributes.qualifications.join(", ")}</Link>
                                                )}
                                            </li>
                                            <div className="mt-2">
                                                <span className="badge bg-soft-primary me-1">Fulltime</span>
                                                <span className="badge bg-soft-warning">Apply</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))}

                        {(allJobList.length > 9 && allJobList.length % 10 == 0 && !loadMore) && <p className='text-primary text-center' style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setLoadMore(true)
                                getjobs(allJobList.length, jobType == "All" ? undefined : jobType, location == "All" ? undefined : location)
                            }}><u>Load More</u></p>}

                        {loadMore && <i className='text-success'>Loading...</i>}
                    </Row>
                </Container>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default JobListTwo
