import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap"
// import Parse from 'parse'
import { Link } from "react-router-dom"
import FeatherIcon from "feather-icons-react"
import SectionTitle from "../../components/Shared/SectionTitle"
import * as Yup from "yup"
import { useFormik } from "formik"

const ContactUs = () => {
  const [succeMsg, setsucceMsg] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      city: "",
      phone: "",
      email: "",
      schoolDetails: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      city: Yup.string().required("Please Enter City/Area"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("Phone is required"),
    }),

    onSubmit: (values) => {
      setsucceMsg(true)
      console.log(values)

      if (values.name !== "" || values.city !== "" || values.phone !== "" || values.email !== "") {
        var name = values.name
        var city = values.city
        var phone = values.phone
        var email = values.email
        var school = values.schoolDetails
        console.log(1)
        fetch(
          // "https://development.back4app.io/addWebsiteQuery", //development
          "https://liltriangle.b4a.app/addWebsiteQuery", //production
          {
            method: "POST",
            body: JSON.stringify({
              name: name,
              city: city,
              email: email,
              phone: phone,
              schoolDetails: school,
              clientType: "New website"

            }),
            headers: {
              "Content-type": "application/json charset=UTF-8",
            },
          }
        ).then((response) => {
          console.log('response', response)
          // setOnSave(false)
          if (response.status === 200) {
            // setResponce("SUCCESS")
            // setError(undefined)
            setTimeout(() => {
              // setResponce(undefined)
            }, 2000)
            clearValues()
          } else {

            response.json().then((data) => {
              console.log(data)
              // setError(data.error)
              // setResponce(undefined)
            })
          }


        }, (error) => {
          console.log("error" + error.message)
        })
      }

    }
  },
  )



  const clearValues = () => {
    validation.resetForm()
  }


  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle title="Get In Touch !" />
        <Row className="align-items-center">
          <Col
            lg="6"
            md={{ size: 6, order: 1 }}
            xs={{ order: 2 }}
            className="mt-4 pt-2"
          >
            <Card
              className="rounded shadow border-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <CardBody>
                <div className="custom-form bg-white">
                  <div id="message"></div>
                  <Alert
                    color="info"
                    isOpen={succeMsg}
                    toggle={() => {
                      setsucceMsg(false)
                    }}
                  >
                    Thanks for enquiring at lil Triangle, one of our executives will get in touch with you shortly.                  </Alert>
                  <Form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    name="contact-form"
                    id="contact-form"
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="textName"
                            type="text"
                            className="form-control ps-5"
                            placeholder="First Name :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            City/Area <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="city"
                            id="textCity"
                            type="text"
                            className="form-control ps-5"
                            placeholder="City/Area:"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.city || ""}
                            invalid={
                              validation.touched.city && validation.errors.city
                                ? true
                                : false
                            }
                          />
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">
                              {validation.errors.city}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Phone Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="phone"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="phone"
                            id="textPhone"
                            type="phone"
                            className="form-control ps-5"
                            placeholder="Your Phone Number :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                                validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                            validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="email"
                            id="textEmail"
                            type="email"
                            className="form-control ps-5"
                            placeholder="Your email :"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                                validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Preschool Details and Query
                          </Label>
                          <textarea
                            name="schoolDetails"
                            id="textSchoolDetails"
                            rows="3"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.schoolDetails || ""}
                          ></textarea>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" className="text-center">
                        <div className="d-grid">
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary"
                            value="Submit"
                          />
                          <div id="simple-msg"></div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col
            lg="6"
            md={{ size: 6, order: 2 }}
            xs={{ order: 1 }}
            className="mt-0 pt-0 order-1 order-md-1"
          >
            <div className="title-heading ms-lg-4">
              <h4
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Contact Details
              </h4>
              <p
                className="text-muted"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                Get your Preschool signed up immediately. Share us some details and we will get in touch with them.
              </p>
              <Card className="border-0 bg-transparent">
                <CardBody className="p-0">
                  <div
                    className="contact-detail d-flex align-items-center mt-2"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="mail"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="content overflow-hidden d-block">
                      <h6 className="fw-bold mb-0">Business Email</h6>
                      <Link to="#" className="text-primary">
                        sales@liltriangle.com
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="border-0 bg-transparent mt-2">
                <CardBody className="p-0">
                  <div
                    className="contact-detail d-flex align-items-center mt-1"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="mail"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="content overflow-hidden d-block">
                      <h6 className="fw-bold mb-0">Support Email</h6>
                      <Link to="#" className="text-primary">
                        hello@liltriangle.com
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card className="border-0 bg-transparent mt-2">
                <CardBody className="p-0">
                  <div
                    className="contact-detail d-flex align-items-center mt-1"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="phone"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="content overflow-hidden d-block">
                      <h6 className="fw-bold mb-0">Phone</h6>
                      <Link to="#" className="text-primary">
                        +91 7676548853
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="border-0 bg-transparent mt-2">
                <CardBody className="p-0">
                  <div
                    className="contact-detail d-flex align-items-center mt-1"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="map-pin"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="content overflow-hidden d-block">
                      <h6 className="fw-bold mb-0">Address</h6>
                      <Link to="#" className="text-primary">
                      Slate and Chalk Innovations Pvt Ltd<br/>
                      #659, 4th Floor, MVS Arcade, 5th Main Road, OMBR Layout,<br/> 
                      B Channasandra, Bengaluru-560043, KA, India
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ContactUs
